.banner {
    margin-left: 8.59375vw;
    margin-right: 7.552083vw;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: -12px;
    z-index: 1;
    border-bottom: 1px solid #444;
    padding: 0;
}

.banner-left {
    width: 42.7083vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.banner-head {
    font-family: 'Poppins Medium';
    font-size: 2.864583vw;
    line-height: 4.27083vw;
    margin-top: 3.5vw;
}
.banner-subhead {
    font-size: 1.822916vw;
    line-height: 4.27083vw;
    color:#E2C621;
}
.banner-watcho-devices {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    padding-top: 1.25vw;
}
.banner-watcho-devices > div {
    font-size: 1.25vw;
    line-height: 1.66666vw;
    font-family: 'Poppins Light';
    width: 7.552083vw;
}

ul.list-devices {
    display: flex;
    gap: 20px;
    align-items: baseline;
}
ul.list-devices li {
    text-align: center;
}
.banner-right {
    width: 37.5vw;
}
.banner-right img {
    max-width: 100%;
    height: 100%;
}
.list-devices img {
    max-width: max(3.489583vw);
}

@media screen and (max-width:767px) {
    .banner {
        padding-left: 3.472vw;
        padding-right: 3.472vw;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .banner-left {
        width: 100%;
        order:2
    }
    .banner-right {
        width: 100%;
        order:1
    }
    .banner-head {
        font-size: 5.9778vw;
        line-height: 7.77vw;
        margin-top: 3.5vw;
        letter-spacing: 0;
    }
    .banner-subhead {
        font-size: 3.68vw;
        line-height: 6.11vw;
        color: #E2C621;
        text-align: center;
    }
    .banner-watcho-devices {
        gap: 20px;
        align-items: center;
        padding-top: 1.25vw;
        flex-direction: column;
    }
    .banner-watcho-devices > div {
        font-size: 3.58vw;
        line-height: 5vw;
        font-family: 'Poppins Light';
        width: 100%;
        text-align: center;
    }

    ul.list-devices {
        display: flex;
        gap: 3.3vw;
        align-items: baseline;
        font-size: 3.05vw;
    }
    .list-devices img {
        max-width: max(12.489583vw);
    }

    .banner{border: none;}

    .banner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 3.472vw;
        padding-right: 3.472vw;
        margin: 0;
    }





}